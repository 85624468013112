import { Grid } from '@chakra-ui/react';
import {
  Form,
  PermissionsGate,
  ResetButton,
  SubmitButton,
  Fieldset,
} from 'components';
import { FormProps } from 'components/Form';
import { Title } from 'components/Meta';
import { FormSkeleton } from 'components/Skeletons';
import { CAN_EDIT_CAMPAIGNS } from 'consts/permissions';
import { withCurrentCampaignId } from 'hoc';
import { useAppSelector } from 'hooks';
import { FormLayout, FormLayoutProps } from 'layouts';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { create, selectCampaign, update } from 'store/campaigns';
import { campaignShowPage } from './Campaign.Links';
import { FORM_VALIDATION_SCHEMA } from './Campaign.schema';
import { CampaignId } from './Campaign.types';
import { CampaignEndDate, CampaignName, CampaignStartDate } from './formFields';
import { useDispatch } from 'react-redux';
import { clearSelectedProducts } from 'store/products';
import { CampaignProducts } from './formFields/Campaign.products';
import { CampaignMediaAgency } from './formFields/Campaign.mediaAgency';
import { CampaignClient } from './formFields/Campaign.client';
import { clearAdded, clearCampaignInfo } from 'store/campaigns';

interface CampaignFormProps extends FormProps, FormLayoutProps {
  redirectOnSuccess?: boolean;
  editMode?: boolean;
  budgetPerLineitem?: boolean;
}

const CampaignForm = ({
  heading,
  defaultValues,
  onSubmit,
  template,
  redirectOnSuccess,
  editMode,
  ...props
}: CampaignFormProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  function handleSubmit(campaign: any): void {
    if (campaign.id && redirectOnSuccess) {
      dispatch(clearAdded());
      dispatch(clearCampaignInfo());
      dispatch(clearSelectedProducts());
      history.push(campaignShowPage(campaign.id));
    }
    if (typeof onSubmit === 'function') onSubmit(campaign);
  }

  return (
    <Form
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      schema={FORM_VALIDATION_SCHEMA}
      permissionsToEdit={[CAN_EDIT_CAMPAIGNS]}
      {...props}
    >
      <FormLayout template={template}>
        <FormLayout.Heading>{heading}</FormLayout.Heading>
        <PermissionsGate requiredPermissions={[CAN_EDIT_CAMPAIGNS]}>
          <FormLayout.Actions>
            <SubmitButton>
              {editMode ? 'Zapisz zmiany' : 'Stwórz kampanię'}
            </SubmitButton>
            <ResetButton />
          </FormLayout.Actions>
        </PermissionsGate>
        <FormLayout.Body>
          <Grid gap={2} templateColumns='repeat(2,1fr)'>
            <Fieldset gridColumn='span 2'>
              <CampaignName gridColumn={{ base: 'span 6', md: 'span 6' }} />
              <CampaignClient
                gridColumn={{ base: 'span 3', md: 'span 3' }}
                isDisabled={editMode}
              />
              <CampaignMediaAgency
                gridColumn={{ base: 'span 3', md: 'span 3' }}
              />
              <CampaignStartDate
                gridColumn={{ base: 'span 6', md: 'span 3' }}
              />
              <CampaignEndDate gridColumn={{ base: 'span 6', md: 'span 3' }} />
              {!editMode && <CampaignProducts gridColumn='span 12' />}
            </Fieldset>
          </Grid>
        </FormLayout.Body>
      </FormLayout>
    </Form>
  );
};

export const CreateCampaignForm = ({
  ...props
}: FormLayoutProps): JSX.Element => {
  const DEFAULT_VALUES = FORM_VALIDATION_SCHEMA.getDefault();
  const campaignInfo = useAppSelector(state => state.campaigns.campaignInfo);
  const values = campaignInfo.name ? campaignInfo : DEFAULT_VALUES;
  return (
    <>
      <Title name={'Stwórz kampanie'} />
      <CampaignForm
        heading={'Stwórz kampanie'}
        messageOnSuccess='Stworzono nową kampanię'
        dispatchMethod={create}
        defaultValues={values}
        redirectOnSuccess={true}
        {...props}
      />
    </>
  );
};

export const EditCampaignForm = ({
  campaignId,
  ...props
}: FormLayoutProps & CampaignId): JSX.Element => {
  const campaignValues = useAppSelector(state =>
    selectCampaign(state, campaignId),
  );

  if (!campaignValues) return <FormSkeleton />;

  return (
    <>
      <Title name={`Edytuj kampanie ${campaignValues.name}`} />
      <CampaignForm
        heading='Edytuj kampanie'
        messageOnSuccess='Wszystkie zmiany zostały zapisane'
        dispatchMethod={update}
        defaultValues={campaignValues}
        editMode={true}
        redirectOnSuccess={true}
        {...props}
      />
    </>
  );
};

export const EditCurrentCampaignForm = withCurrentCampaignId(EditCampaignForm);
