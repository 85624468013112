import React from 'react';
import { ModalInfo } from 'components';
import { Text, ModalHeader, ModalBody, Grid } from '@chakra-ui/react';
import { ProductParams, selectProduct } from 'store/products';
import { Fieldset } from 'components';
import {
  SingleField,
  MultiOptionField,
  FileField,
} from 'components/ModalInfo.Fields';
import {
  ProductCategoryInfo,
  ProductAdSlotsInfo,
  ProductAdFormatInfo,
  ProductSegmentInfo,
} from 'components/Product/infoFields';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { fetchById } from 'store/products';
import { FieldsetSkeleton } from 'components/Skeletons';

export const ShowProductModalInfo = ({
  text,
  product,
}: {
  text: string;
  product: ProductParams;
}): JSX.Element => {
  return (
    <ModalInfo
      trigger={
        <Link to='#'>
          <Text variant='link' as='span'>
            {text}
          </Text>
        </Link>
      }
      info={<ProductModalInfo {...product} />}
    />
  );
};

export const ShowProductSnapshotModalInfo = ({
  text,
  product,
}: {
  text: string;
  product: ProductParams;
}): JSX.Element => {
  return (
    <ModalInfo
      trigger={
        <Link to='#'>
          <Text variant='link' as='span'>
            {text}
          </Text>
        </Link>
      }
      info={<ProductSnapshotModalInfo {...product} />}
    />
  );
};

export const ProductModalDisplay = ({
  product,
}: {
  product: ProductParams;
}): JSX.Element => {
  const { generalValue, timedSegments } = product || {};
  return (
    <>
      <ModalHeader mb={4}>Product info</ModalHeader>
      <ModalBody>
        <Grid gap={6} templateColumns='repeat(3,1fr)'>
          <Fieldset gridColumn='span 3'>
            <SingleField
              header='Nazwa produktu'
              value={generalValue.name}
              gridColumn={{ base: 'span 12', md: 'span 12' }}
            />
          </Fieldset>
          {timedSegments.map((segment, index) => (
            <ProductSegmentInfo
              key={index}
              value={segment}
              gridColumn={{ base: 'span 12', md: 'span 12' }}
            />
          ))}
          <Fieldset gridColumn='span 3'>
            <SingleField
              header='Rodzaj emisji'
              value={generalValue.adType}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
            <SingleField
              header='Model rozliczeniowy'
              value={generalValue.pricingModel}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
            <MultiOptionField
              header='Miejsce emisji'
              elements={generalValue.emissionModels}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
          </Fieldset>
          <Fieldset gridColumn='span 3'>
            <SingleField
              header='Serwisy/Aplikacje'
              value={generalValue.services}
              gridColumn={{ base: 'span 12', md: 'span 12' }}
            />
          </Fieldset>
          <Fieldset gridColumn='span 3'>
            <ProductCategoryInfo
              value={generalValue.categoryIds}
              gridColumn={{ base: 'span 12', md: 'span 12' }}
            />
          </Fieldset>
          <Fieldset gridColumn='span 3'>
            <ProductAdSlotsInfo
              value={generalValue.adSlotIds}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
            <ProductAdFormatInfo
              value={generalValue.adFormatIds}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
            <SingleField
              header='Platforma emisji'
              value={generalValue.emissionPlatform}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
          </Fieldset>
          <Fieldset gridColumn='span 3'>
            <FileField
              header='Wizualizacja'
              value={generalValue.visualSpec}
              gridColumn={{ base: 'span 6', md: 'span 6' }}
            />
            <FileField
              header='Specyfikacja techniczna'
              value={generalValue.technicalSpec}
              gridColumn={{ base: 'span 6', md: 'span 6' }}
            />
          </Fieldset>
          <Fieldset gridColumn='span 3'>
            <SingleField
              header='Komentarz'
              value={generalValue.commentary}
              gridColumn={{ base: 'span 12', md: 'span 12' }}
            />
          </Fieldset>
        </Grid>
      </ModalBody>
    </>
  );
};

export const ProductModalInfo = (product: ProductParams): JSX.Element => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(fetchById({ productId: product.id }));
  }, [dispatch, product.id]);

  const productValues = useAppSelector(state =>
    selectProduct(state, product.id),
  );
  if (!productValues?.timedSegments) return <FieldsetSkeleton />;

  return <ProductModalDisplay product={productValues} />;
};

export const ProductSnapshotModalInfo = (
  product: ProductParams,
): JSX.Element => {
  return <ProductModalDisplay product={product} />;
};
