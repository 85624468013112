import React from 'react';
import { useAppSelector } from 'hooks';
import { selectSelectedIds, selectSelectedProducts } from 'store/products';
import Table from 'layouts/Table';
import { ProductItem } from 'components/Campaign';
import { useFormContext } from 'react-hook-form';
import { Box, FormLabel, Flex, Button, Spacer } from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';
import { marketListPage } from 'components/Market';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { updateCampaignInfo, setAdded } from 'store/campaigns';

export const CampaignProducts = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const { setValue, getValues } = useFormContext();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const selectedProductIds = useAppSelector(selectSelectedIds);
  const productList = useAppSelector(selectSelectedProducts);

  React.useEffect(() => {
    setValue('products', selectedProductIds);
  }, [selectedProductIds]);

  function handleAddProducts() {
    dispatch(updateCampaignInfo(getValues()));
    dispatch(setAdded());
    history.push(marketListPage());
  }

  return (
    <Box {...props}>
      <Flex pb={5}>
        <FormLabel>Wybrane produkty</FormLabel>
        <Spacer />
        <Button
          onClick={handleAddProducts}
          variant='primary'
          size='sm'
          leftIcon={<FiPlus />}
        >
          Dodaj produkty
        </Button>
      </Flex>

      <Table>
        <Table.Header>
          <Table.Column>Nazwa</Table.Column>
          <Table.Column>Wydawca</Table.Column>
          <Table.Column>Placement</Table.Column>
          <Table.Column>Model</Table.Column>
          <Table.Column>Stawka</Table.Column>
          <Table.Column shrink>Akcje</Table.Column>
        </Table.Header>
        <Table.Body>
          {productList.map(product => (
            <ProductItem key={product.id} product={product} />
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
};
