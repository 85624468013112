import React from 'react';
import { Fieldset } from 'components';
import { SingleField } from 'components/ModalInfo.Fields';
import { ProductTimedValueParams } from 'store/products';
import { format } from 'date-fns';

const ProductSegmentInfo = ({
  value,
  ...props
}: {
  value: ProductTimedValueParams;
  [key: string]: any;
}): JSX.Element => {
  return (
    <Fieldset gridColumn='span 3'>
      <SingleField
        header='Data startu'
        value={format(value.startDate, 'dd.MM.yyyy')}
        gridColumn={{ base: 'span 6', md: 'span 6' }}
        isDate
      />
      <SingleField
        header='Data zakończenia'
        value={format(value.endDate, 'dd.MM.yyyy')}
        gridColumn={{ base: 'span 6', md: 'span 6' }}
        isDate
      />
      <SingleField
        header='Odsłony'
        value={value.impressions}
        gridColumn={{ base: 'span 4', md: 'span 4' }}
      />
      <SingleField
        header='Kliknięcia'
        value={value.clicks}
        gridColumn={{ base: 'span 4', md: 'span 4' }}
      />
      <SingleField
        header='CTR'
        value={`${value.ctr}%`}
        gridColumn={{ base: 'span 4', md: 'span 4' }}
      />
      <SingleField
        header='Unikalni użytkownicy'
        value={value.uniqueUsers}
        gridColumn={{ base: 'span 4', md: 'span 4' }}
      />
      <SingleField
        header='Viewability'
        value={value.viewability}
        gridColumn={{ base: 'span 4', md: 'span 4' }}
      />
      <SingleField
        header='Stawka RC'
        value={`${value.price} zł`}
        gridColumn={{ base: 'span 4', md: 'span 4' }}
      />
    </Fieldset>
  );
};

ProductSegmentInfo.displayName = 'Product.segmentInfo';

export { ProductSegmentInfo };
