import React from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks';
import {
  Box,
  UnorderedList,
  ListItem,
  useToast,
  ToastId,
} from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { formContext } from './Form.Context';

const ERROR_CODES: { [key: string]: string } = {
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
};

export interface FormProps {
  messageOnSuccess?: string;
  children?: React.ReactNode;
  defaultValues?: Record<string, any>;
  schema?: AnyObjectSchema;
  permissionsToEdit?: string[];
  operationType?: 'create' | 'update';
  context?: Record<string, any>;
  diff?: Record<string, any>;
  dispatchMethod: (values: Record<string, any>) => any;
  onSubmit?: (values: Record<string, any>) => void;
  [key: string]: any;
  disableAll?: boolean;
  id?: string;
  reference?: React.RefObject<HTMLFormElement>;
}

export interface DiffProps {
  field: string;
  old: string | number | boolean | string[] | number[] | boolean[];
  new: string | number | boolean | string[] | number[] | boolean[];
}

interface UiError {
  key: string;
  message: string;
}

function handleReactHookFormErrors(
  array: Array<[string, Record<string, any>]>,
): UiError[] {
  const errors: UiError[] = [];
  array.forEach(([, errorObject]) => {
    errors.push(...handleErrorObject(errorObject));
  });
  return errors;
}

function handleErrorObject(errorObject: Record<string, any>): UiError[] {
  if ('message' in errorObject) {
    return [{ key: errorObject?.ref?.name, message: errorObject.message }];
  }

  const errors: UiError[] = [];
  for (const key in errorObject) {
    errors.push(...handleErrorObject(errorObject[key]));
  }

  return errors;
}

function handleErrorMessageFromApi(obj: { [key: string]: any }) {
  const res: { [key: string]: string } = {};
  function recurse(obj: { [key: string]: any }, current?: string) {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      const newKey = current ? current + '.' + key : key;
      if (
        value &&
        typeof value === 'object' &&
        !(value instanceof Date) &&
        !Array.isArray(value)
      ) {
        recurse(value, newKey);
      } else {
        res[newKey] = value;
      }
    });
  }
  recurse(obj);
  return res;
}

/**
 * Provider dla formularzy w oparciu o react-hook-form i useForm
 * @see https://react-hook-form.com/api/useform
 */
export const Form = ({
  messageOnSuccess,
  dispatchMethod,
  onSubmit,
  children,
  defaultValues,
  schema,
  permissionsToEdit = [],
  context,
  diff,
  disableAll,
  id,
  reference,
  ...props
}: FormProps): JSX.Element => {
  const toast = useToast();
  const toastIdRef = React.useRef<ToastId | undefined>();
  const dispatch = useAppDispatch();
  const reactHookForm = useForm({
    ...props,
    resolver: schema && yupResolver(schema),
    defaultValues,
  });

  function showErrorAsToast(
    title: string,
    description?: string | React.ReactNode,
  ): void {
    if (toastIdRef.current) {
      toast.update(toastIdRef.current, {
        title,
        description,
        status: 'error',
        isClosable: true,
        duration: null,
      });
    } else {
      toastIdRef.current = toast({
        title,
        description,
        position: 'bottom',
        status: 'error',
        isClosable: true,
        duration: null,
      });
    }
  }

  function hideToast(): void {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
      toastIdRef.current = undefined;
    }
  }

  function showSuccessAsToast(): void {
    hideToast();
    toast({
      title: 'Sukces',
      description: messageOnSuccess,
      position: 'bottom',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  }

  function handleErrorFromApi(response: any): void {
    const { code } = response;
    if (!code) {
      showErrorAsToast('Coś poszło nie tak');
      return;
    }
    switch (code) {
      case 400: {
        const message = handleErrorMessageFromApi(response.message);
        showErrorAsToast(
          typeof message === 'string' ? message : 'Formularz zawiera błędy',
        );
        if (typeof message === 'object') {
          Object.keys(message).forEach(key => {
            reactHookForm.setError(key, { message: message[key] });
          });
        }
        break;
      }
      default:
        showErrorAsToast(`${code}: ${ERROR_CODES[code]}`);
        break;
    }
  }

  React.useImperativeHandle(reference, () => ({
    submitForm() {
      reactHookForm.handleSubmit(handleSubmit)();
    },
  }));

  async function handleSubmit(values: Record<string, any>): Promise<any> {
    try {
      const response = unwrapResult(await dispatch(dispatchMethod(values)));
      showSuccessAsToast();
      if (typeof onSubmit === 'function') onSubmit(response);
    } catch (error) {
      handleErrorFromApi(error);
    }
  }

  function resetForm() {
    reactHookForm.reset(defaultValues);
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  function checkFiledDiff(name: string): DiffProps | undefined {
    if (!diff) return undefined;
    return diff.find((item: DiffProps) => item.field === name);
  }

  React.useEffect(() => {
    const errors = Object.entries(reactHookForm.formState.errors);

    if (errors.length) {
      const errorDescription = (
        <UnorderedList>
          {handleReactHookFormErrors(errors).map(({ key, message }, index) => (
            <ListItem key={key || index}>{message}</ListItem>
          ))}
        </UnorderedList>
      );
      showErrorAsToast('Formularz zawiera błędy', errorDescription);
    } else {
      hideToast();
    }
  }, [reactHookForm.formState]);

  React.useEffect(() => {
    return () => {
      hideToast();
    };
  }, []);

  return (
    <formContext.Provider
      value={{
        ...context,
        diff,
        checkFiledDiff,
        permissionsToEdit,
        defaultValues,
        resetForm,
        disableAll,
      }}
    >
      <FormProvider {...reactHookForm}>
        <Box
          as='form'
          onSubmit={reactHookForm.handleSubmit(handleSubmit)}
          onKeyPress={handleKeyPress}
          id={id}
        >
          {children}
        </Box>
      </FormProvider>
    </formContext.Provider>
  );
};
