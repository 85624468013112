import { RootState } from 'store';
import adapter from './flights.adapter';
import { REDUCER_KEY } from './flights.consts';
import { FlightParams } from './flights.types';
import { createSelector } from 'reselect';
import { SurchargesNames } from './flights.types';

const selectors = adapter.getSelectors(
  (state: RootState) => state[REDUCER_KEY],
);
export const { selectIds, selectTotal, selectAll } = selectors;

export const selectById = (state: RootState, id: number): FlightParams | null =>
  selectors.selectById(state, id) || null;

export function selectFlight(
  state: RootState,
  flightId: number,
): FlightParams | undefined {
  return selectors.selectById(state, flightId) || undefined;
}

export const selectAllFlights = createSelector(
  state => selectors.selectAll(state),
  flights => flights || [],
);

export function selectFlightSurcharges(
  state: RootState,
  flightId: number,
): SurchargesNames[] {
  const flight = selectors.selectById(state, flightId);
  return flight?.surcharges || [];
}

export function selectIfFlightHaveHistory(
  state: RootState,
  flightId: number,
): boolean {
  const flight = selectors.selectById(state, flightId);
  return !!flight?.history;
}
