import { createSlice } from '@reduxjs/toolkit';
import { NO_CURRENT_ENTITY } from 'consts/store';
import { DATA_UNDEFINED, NO_ERRORS } from 'consts/xhr';
import {
  pendingActionHandler,
  readyActionHandler,
  rejectActionHandler,
} from 'helpers/sliceHelpers';
import {
  create,
  fetchAllProducts,
  remove,
  setCurrentProduct as setCurrent,
  update,
  toggleStatus,
  fetchById,
} from './products.actions';
import adapter from './products.adapter';
import { REDUCER_KEY } from './products.consts';
import { ProductParams } from './products.types';

const slice = createSlice({
  name: REDUCER_KEY,
  initialState: {
    ...adapter.getInitialState(),
    status: DATA_UNDEFINED,
    errors: NO_ERRORS,
    current: NO_CURRENT_ENTITY,
    selected: [],
  },
  reducers: {
    addSelectedProduct: (state: { selected: ProductParams[] }, action) => {
      state.selected.push(action.payload);
    },
    removeSelectedProduct: (state: { selected: ProductParams[] }, action) => {
      state.selected = state.selected.filter(({ id }) => id !== action.payload);
    },
    clearSelectedProducts: (state: { selected: ProductParams[] }) => {
      state.selected = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        adapter.removeAll(state);
        adapter.upsertMany(state, action.payload);
      })
      .addCase(create.fulfilled, (state, action) =>
        adapter.addOne(state, action.payload),
      )
      .addCase(update.fulfilled, (state, action) =>
        adapter.upsertOne(state, action.payload),
      )
      .addCase(remove.fulfilled, (state, action) =>
        adapter.removeOne(state, action.payload),
      )
      .addCase(setCurrent.pending, (state, action) => {
        state.current = action.meta.arg;
      })
      .addCase(fetchById.fulfilled, (state, action) => {
        adapter.upsertOne(state, action.payload);
      })
      .addCase(setCurrent.fulfilled, (state, action) => {
        if (action.payload) {
          state.current = action.payload.id;
          adapter.upsertOne(state, action.payload);
        } else {
          state.current = NO_CURRENT_ENTITY;
        }
      })
      .addCase(REDUCER_KEY + '/removeAll', state => adapter.removeAll(state))
      .addCase(toggleStatus.fulfilled, (state, action) => {
        adapter.upsertOne(state, action.payload);
      })
      .addMatcher(
        ({ type }) =>
          type.startsWith(REDUCER_KEY) && type.endsWith('fulfilled'),
        readyActionHandler,
      )
      .addMatcher(
        ({ type }) => type.startsWith(REDUCER_KEY) && type.endsWith('pending'),
        pendingActionHandler,
      )
      .addMatcher(
        ({ type }) => type.startsWith(REDUCER_KEY) && type.endsWith('rejected'),
        rejectActionHandler,
      );
  },
});

export const {
  addSelectedProduct,
  removeSelectedProduct,
  clearSelectedProducts,
} = slice.actions;
export const { reducer } = slice;
