import { createSelector } from 'reselect';
import adapter from './campaigns.adapter';
import { REDUCER_KEY } from './campaigns.consts';
import { isBefore, startOfToday, isAfter } from 'date-fns';

const selectors = adapter.getSelectors(state => state[REDUCER_KEY]);
export const { selectIds, selectEntities, selectTotal } = selectors;

export const selectById = (state, id) => selectors.selectById(state, id) || {};
export const selectCampaignById = selectById;
export const selectCurrentCampaignId = state => state[REDUCER_KEY].current;

export const selectAllCampaigns = createSelector(
  state => selectors.selectAll(state),
  campaigns => campaigns || [],
);

export const selectActiveCampaigns = createSelector(
  state => selectAllCampaigns(state),
  campaigns =>
    campaigns.filter(
      campaign =>
        campaign.isActive === true &&
        isAfter(campaign?.startDate, startOfToday()) === false &&
        isBefore(campaign?.endDate, startOfToday()) === false,
    ),
);

export const selectInactiveCampaigns = createSelector(
  state => selectAllCampaigns(state),
  campaigns =>
    campaigns.filter(
      campaign =>
        campaign.isActive === false &&
        isBefore(campaign?.endDate, startOfToday()) === false,
    ),
);

export const selectPendingCampaigns = createSelector(
  state => selectAllCampaigns(state),
  campaigns =>
    campaigns.filter(
      campaign =>
        campaign.isActive === true &&
        isAfter(campaign?.startDate, startOfToday()) === true,
    ),
);

export const selectFinishedCampaigns = createSelector(
  state => selectAllCampaigns(state),
  campaigns =>
    campaigns.filter(
      campaign => isBefore(campaign.endDate, startOfToday()) === true,
    ),
);

export const selectCurrentCampaign = createSelector(
  state => selectCurrentCampaignId(state),
  state => selectAllCampaigns(state),
  (currentId, campaigns) =>
    campaigns.find(({ id }) => id === currentId) || undefined,
);

export function selectCampaign(state, campaignId) {
  return selectors.selectById(state, campaignId) || undefined;
}

export function selectCampaignName(state, campaignId) {
  return selectCampaign(state, campaignId)?.name || '';
}

export function selectCampaignClient(state, campaignId) {
  return selectCampaign(state, campaignId)?.client || '';
}

export function selectIsCampaignAdded(state) {
  return state[REDUCER_KEY].isAdded;
}

export const selectCurrentCampaignDates = createSelector(
  selectCurrentCampaign,
  currentCampaign => {
    if (!currentCampaign) return {};

    return {
      startDate: currentCampaign.startDate,
      endDate: currentCampaign.endDate,
    };
  },
);
