import React from 'react';
import PropTypes from 'prop-types';
import BreadcrumbsContainer from '../components/Breadcrumbs';
import { Grid, Flex, Heading, Text } from '@chakra-ui/react';

const Layout = ({ children }) => (
  <Grid
    templateColumns='1fr 1fr'
    templateRows='auto auto auto 1fr'
    templateAreas={`
      "breadcrumbs breadcrumbs"
      "header actions"
      "date date"
      "body body"
    `}
    gap={2}
    px={6}
    h='100%'
    overflow='auto'
  >
    {children}
  </Grid>
);
Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const Breadcrumbs = ({ children }) => (
  <BreadcrumbsContainer gridArea='breadcrumbs'>{children}</BreadcrumbsContainer>
);
Breadcrumbs.propTypes = {
  children: PropTypes.node.isRequired,
};

const Header = ({ children }) => (
  <Heading
    as='h2'
    size='lg'
    gridArea='header'
    fontWeight='bold'
    position='sticky'
  >
    {children}
  </Heading>
);
Header.propTypes = {
  children: PropTypes.node,
};

const Actions = ({ children }) => (
  <Flex justifyContent='flex-end' gridGap={2} gridArea='actions'>
    {children}
  </Flex>
);

Actions.propTypes = {
  children: PropTypes.node,
};

const Body = ({ children, ...props }) => (
  <Grid gridArea='body' gridGap={6} gridAutoRows='min-content' {...props}>
    {children}
  </Grid>
);

Body.propTypes = {
  children: PropTypes.node,
};

const Date = ({ children }) => (
  <Text gridArea='date' align='flex-start'>
    {children}
  </Text>
);

Date.propTypes = {
  children: PropTypes.node,
};

Layout.Breadcrumbs = Breadcrumbs;
Layout.Header = Header;
Layout.Actions = Actions;
Layout.Body = Body;
Layout.Date = Date;

export default Layout;
